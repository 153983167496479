<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="f-dialog edit-dialog"
    :title="options.id ? '编辑认证用户' : '添加认证用户'"
    width="820px"
    top="50px"
    :close-on-click-modal="false"
    @open="openDialog"
    @close="resetForm()"
  >
    <el-form class="form" ref="form" label-width="120px" :model="formData">
      <el-form-item
        label="关联用户："
        prop="customerId"
        v-if="!currentUser || (currentUser && !currentUser.isEdit)"
      >
        <p class="tips2">
          请从当前站点注册为“平台员工”用户中选择需要认证的用户
        </p>
        <el-autocomplete
          style="width:260px;"
          prefix-icon="el-icon-search"
          v-model="searchUserKeyword"
          value-key="name"
          :fetch-suggestions="querySearchAsync"
          :hide-loading="hideFetchLoading"
          placeholder="请输入"
          @select="handleUserSelect"
        >
          <div class="user-dropdown" slot-scope="{ item }">
            <el-image class="avatar" fit="cover" :src="item.avatarPath">
              <div slot="error">
                <img :src="$defaultAvatarUrl" />
              </div>
            </el-image>
            <p>
              {{ item.customerName }}
              <span class="color9">{{ item.cellphone | formatPhone }}</span>
            </p>
          </div>
        </el-autocomplete>
        <div class="current-user x3 aic" v-if="currentUser">
          <div class="x aic">
            <el-image class="avatar" fit="cover" :src="currentUser.avatarPath">
              <div slot="error">
                <img :src="$defaultAvatarUrl" />
              </div>
            </el-image>
            <p>
              {{ currentUser.customerName }}
              <span class="color9">{{
                currentUser.cellphone | formatPhone
              }}</span>
            </p>
          </div>
          <svg-icon
            v-if="!formData.id"
            icon-class="delete"
            class="fs20 pointer"
            @click="currentUser = null"
          ></svg-icon>
        </div>
      </el-form-item>
      <el-form-item v-else label-width="40px">
        <div class="current-user x3 aic" v-if="currentUser">
          <div class="x aic">
            <el-image class="avatar" fit="cover" :src="currentUser.avatarPath">
              <div slot="error">
                <img :src="$defaultAvatarUrl" />
              </div>
            </el-image>
            <p>
              {{ currentUser.name }}
              <span class="color9">{{
                currentUser.cellphone | formatPhone
              }}</span>
            </p>
          </div>
          <p class="tips2">不支持变更用户</p>
        </div>
      </el-form-item>
      <el-form-item
        label="认证类型:"
        prop="authType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.authType" class="f-radio-oval">
          <el-radio
            border
            :label="item.value"
            v-for="(item, index) in authTypes"
            :key="index"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="authSlaveTypeList.length"
        label="认证标识："
        prop="authSlaveType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.authSlaveType">
          <el-radio-button
            :label="item.value"
            v-for="item in authSlaveTypeList"
            :key="item.value"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        prop="permissionList"
        label="应用权限："
        v-if="permissionList.length"
      >
        <el-checkbox-group v-model="formData.permissionList">
          <el-checkbox
            v-for="item of permissionList"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        :label="avatarLabel"
        prop="authAvatarPath"
        :rules="[$formRules.required()]"
      >
        <f-upload
          required
          :type="2"
          :default-file-list="
            formData.authAvatarPath ? [formData.authAvatarPath] : undefined
          "
          @on-success="avatarUploadSuccess"
        ></f-upload>
      </el-form-item>
      <template
        v-if="
          (formData.authType == 1 && formData.authSlaveType == 2) ||
            formData.authType == 3
        "
      >
        <el-form-item
          prop="authName"
          label="姓名称呼："
          :rules="[$formRules.required()]"
        >
          <el-input
            class="input"
            v-model="formData.authName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop="estateIdList"
          label="关联楼盘："
          :rules="[$formRules.required()]"
        >
          <autocomplete
            style="width:260px"
            class="input"
            keywordName="estateName"
            :defaultKeyword="defaultKeyword"
            :options="autocompleteOptions"
            @setMoreValue="addEstateTag"
            :currentValue="formData.estateId"
          />
          <div class="estate-tags" v-if="estates.length">
            <el-tag
              :key="tag.id"
              v-for="tag in estates"
              closable
              :disable-transitions="false"
              @close="removeEstateTag(tag)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </el-form-item>
      </template>
      <template v-if="formData.authType != 3 && formData.authSlaveType != 2">
        <el-form-item
          prop="authName"
          label="认证名称："
          :rules="[$formRules.required()]"
        >
          <el-input
            class="input"
            v-model="formData.authName"
            placeholder="建议不超过8个汉字"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="description"
          label="一句话描述："
          :rules="[$formRules.required()]"
        >
          <el-input
            class="input"
            v-model="formData.description"
            placeholder="建议不超过15个汉字"
          ></el-input>
        </el-form-item>
      </template>
      <template v-if="formData.authType != 2">
        <!--AI名片，媒体号不显示-->
        <el-form-item
          label="AI名片："
          prop="aiCardStatus"
          :rules="[$formRules.required()]"
          v-if="formData.authType == 1 && formData.authSlaveType !== 2"
        >
          <el-radio-group v-model="formData.aiCardStatus">
            <el-radio-button :label="true">开启</el-radio-button>
            <el-radio-button :label="false">关闭</el-radio-button>
          </el-radio-group>
          <p class="tips">
            开启后，该用户可在个人主页维护名片内容；名片将呈现在个人主页，以及从挑房页面访问推荐楼盘时，楼盘联系人将替换成该用户名片；
          </p>
        </el-form-item>
        <template v-else>
          <el-form-item
            label="联系电话："
            prop="cardEditVo.cellphone"
            :rules="[$formRules.required(), $formRules.phoneAll]"
          >
            <el-input
              class="input"
              v-model="formData.cardEditVo.cellphone"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item prop="cardEditVo.wechatNo" label="微信号：">
            <el-input
              class="input"
              v-model="formData.cardEditVo.wechatNo"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item prop="cardEditVo.wechatQrCode" label="微信二维码：">
            <f-upload
              :type="2"
              :default-file-list="
                formData.cardEditVo.wechatQrCode
                  ? [formData.cardEditVo.wechatQrCode]
                  : undefined
              "
              @on-success="qrUploadSuccess"
            ></f-upload>
          </el-form-item>
        </template>
      </template>
    </el-form>
    <div slot="footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FUpload from "@/components/upload";
import {
  insertCustomerAuth,
  editCustomerAuth,
  getEstateByName,
  authCustomerEstateList
} from "@/api/visitorManage";
import { getCustomerListByKeyword } from "@/api/setting";
import autocomplete from "@/components/autocomplete";
import config from "../_js/config";
const formData = {
  aiCardStatus: false, //AI名片状态：0-关闭，1-开启 ,
  authAvatarPath: "", //认证头像 ,
  authName: "", //认证名称
  authType: null, //认证类型：1-分析师 2-媒体号 3-营销团队
  authSlaveType: null, //认证类型：1-地产达人,2-置业顾问,3-PCN经纪 , 5 新房助理 6驻场客服 7项目经理
  customerId: null, // 用户id ,
  description: "", //描述
  id: null,
  estateId: "",
  estateIdList: [], // 楼盘ids
  cardEditVo: {
    cellphone: "",
    wechatNo: "",
    wechatQrCode: ""
  },
  permissionList: []
};
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    authTypes: {
      // 认证类型列表
      type: Array,
      default: () => []
    }
  },
  components: {
    autocomplete,
    FUpload
  },
  data() {
    return {
      ...config,
      hideFetchLoading: false,
      currentUser: null,
      formData: this.$deepClone(formData),
      searchUserKeyword: "", //查询用户关键字
      autocompleteOptions: {
        placeholder: "请输入“楼盘名称”搜索",
        name: "estateName", // 键名
        value: "estateId", // 键值
        func: getEstateByName // 搜索的函数
      },
      defaultKeyword: "",
      distributionFlag: false, // 判断是否为分销平台
      estates: [], // 关联楼盘
      authSlaveTypeList: [] // 认证标识列表
    };
  },
  computed: {
    avatarLabel() {
      let str = "";
      if (this.formData.authType == 3) {
        str = "用户头像：";
      } else if (
        this.formData.authType == 1 &&
        this.formData.authSlaveType == 2
      ) {
        str = "置业顾问头像：";
      } else {
        str = "认证头像：";
      }
      return str;
    },
    permissionList() {
      const { type } = this.$platformInfo;
      const list = [
        { label: "邀请注册", value: "801", platSetting: 2, platformType: 2 },
        { label: "渠道管理", value: "803", platformType: 2 },
        { label: "分配任务", value: "805", platformType: 2 },
        { label: "签到管理", value: "1001", platformType: 2 }
      ];
      return list.filter(
        item =>
          item.platformType === type &&
          !item.hide &&
          (item.platSetting === undefined || this.$platSs(item.platSetting))
      );
    }
  },
  watch: {
    estates: {
      immediate: true,
      deep: true,
      handler(arr) {
        // 关联楼盘id
        this.formData.estateIdList = arr.map(item => item.id);
      }
    },
    "formData.authType": {
      handler(type) {
        // 根据类型，设置认证标识
        if (type != 2) {
          this.authSlaveTypeList =
            this.authTypes.find(item => item.value == type)?.children || [];
        } else {
          // 媒体号没有认证标识
          this.authSlaveTypeList = [];
        }
      }
    }
  },
  created() {
    // 判断是否为分销平台
    this.distributionFlag = this.$route.path.includes("distribution");
    this.openDialog();
  },
  methods: {
    async openDialog() {
      // 初始化数据
      if (this.options.id) {
        this.getEstateList(); // 获取关联楼盘
        let form = this.$deepClone({
          ...this.options,
          permissionList: this.options.permissionList || []
        });
        form = {
          ...this.$deepClone(formData),
          ...form
        };
        form.cardEditVo = {
          ...this.$deepClone(formData.cardEditVo),
          ...form.cardEditVo
        };
        this.formData = form;
        if (this.options.bindingInfo && this.options.bindingInfo.bindingId) {
          // 关联用户
          this.currentUser = {
            avatarImagePath: this.options.bindingInfo.bindingAvatarPath,
            cellphone: this.options.bindingInfo.bindingPhone,
            name: this.options.bindingInfo.bindingName,
            isEdit: true
          };
        } else {
          this.currentUser = null;
        }
        this.defaultKeyword = form.estateName;
        this.formData.authType = this.formData.authType.toString(); // 转化为字符串,与认证类型列表保持一致
      } else {
        this.currentUser = null;
        this.defaultKeyword = "";
        this.formData = this.$deepClone(formData);
        this.formData.authType = this.authTypes[0].value; // 新增时，认证类型，默认选中第一个
      }
    },
    avatarUploadSuccess(fileList) {
      // 认证头像上传成功
      this.formData.authAvatarPath = fileList[0].url;
    },
    qrUploadSuccess(fileList) {
      // 二维码上传成功
      this.formData.cardEditVo.wechatQrCode = fileList[0].url;
    },
    async querySearchAsync(queryString, cb) {
      // 查询用户列表
      this.hideFetchLoading = true;
      if (!queryString || !queryString.trim()) return;
      this.hideFetchLoading = false;
      let query = {
        keyword: queryString
      };
      let data = await getCustomerListByKeyword(query);
      data = data || [];
      this.hideFetchLoading = true;
      cb(data);
    },
    async handleUserSelect(item) {
      // 选择用户
      this.currentUser = item;
      this.formData.customerId = item.customerId;
      if (!this.options.id) {
        // 新增时，填充姓名、头像、AI名片中的联系电话
        this.formData.authName = item.customerName;
        this.formData.authAvatarPath = item.avatarPath;
        this.formData.cardEditVo.cellphone = item.cellphone;
      }
    },
    async getEstateList() {
      // 获取当前认证用户，关联的楼盘列表
      const res = await authCustomerEstateList(this.options.id);
      if (res) {
        this.estates = res.map(item => {
          return {
            id: item.estateId,
            name: item.estateName
          };
        });
      }
    },
    addEstateTag(e) {
      // 添加楼盘标签
      if (this.estates.find(item => item.id == e.value)) return;
      this.estates.push({ id: e.value, name: e.name });
    },
    removeEstateTag(tag) {
      // 移除楼盘标签
      this.estates = this.estates.filter(item => item.id != tag.id);
    },
    resetForm() {
      // this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    submit() {
      //提交
      // this.formData.customerId = this.currentUser?.customerId;
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const fn = this.formData.id ? editCustomerAuth : insertCustomerAuth;
        const res = await fn(this.formData);
        if (res) {
          this.resetForm();
          this.$emit("refresh");
          this.$showSuccess("操作成功");
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.user-auth {
  padding: 30px;
  min-height: 100%;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
  background: #fff;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .edit-dialog {
    .current-user {
      height: 55px;
      background: #fafafc;
      padding: 0 16px;
      margin-top: 10px;
      .user-avatar {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }
    .form {
      padding: 0 60px 0 0;
      .auth-content-wrap {
        display: flex;
      }
      .input-group {
        flex: 1;
        .item {
          & + .item {
            margin-top: 30px;
          }
          p {
            span {
              color: #999;
            }
          }
          .input {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
      .tips {
        margin-top: 20px;
        font-size: 13px;
        color: #aaa;
        line-height: 1.5;
      }
      .tips2 {
        line-height: 40px;
        color: #8691a3;
      }
    }
  }
  .current-user {
    .avatar {
      margin-right: 10px;
    }
  }
  .filter {
    padding-top: 20px;
    .select {
      width: 150px;
      margin-left: 10px;
    }
  }
  .tip {
    color: #aaa;
    font-size: 12px;
    margin-top: 5px;
  }
}
.el-radio-group {
  display: flex;
}
.el-radio {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 20px 0 10px;
  min-width: 110px;
  .title {
    font-size: 14px;
    color: #333;
    line-height: 100%;
  }
  p {
    font-size: 13px;
    color: #aaa;
    line-height: 100%;
    margin-top: 5px;
  }
}
.f-radio-oval {
  .el-radio.is-bordered {
    border-radius: 24px;
  }
  .el-radio.is-checked {
    background-color: #e1eafe;
  }
}
.el-form-item {
  margin-bottom: 15px;
  .el-input {
    width: 260px;
  }
}
.estate-tags {
  .el-tag {
    margin-right: 10px;
    margin-top: 10px;
  }
}
::v-deep .el-dialog {
  height: calc(100vh - 100px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .el-dialog__body {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
