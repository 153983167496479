var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog edit-dialog",
    attrs: {
      "title": _vm.options.id ? '编辑认证用户' : '添加认证用户',
      "width": "820px",
      "top": "50px",
      "close-on-click-modal": false
    },
    on: {
      "open": _vm.openDialog,
      "close": function close($event) {
        return _vm.resetForm();
      }
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "label-width": "120px",
      "model": _vm.formData
    }
  }, [!_vm.currentUser || _vm.currentUser && !_vm.currentUser.isEdit ? _c('el-form-item', {
    attrs: {
      "label": "关联用户：",
      "prop": "customerId"
    }
  }, [_c('p', {
    staticClass: "tips2"
  }, [_vm._v(" 请从当前站点注册为“平台员工”用户中选择需要认证的用户 ")]), _c('el-autocomplete', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "prefix-icon": "el-icon-search",
      "value-key": "name",
      "fetch-suggestions": _vm.querySearchAsync,
      "hide-loading": _vm.hideFetchLoading,
      "placeholder": "请输入"
    },
    on: {
      "select": _vm.handleUserSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _c('div', {
          staticClass: "user-dropdown"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "fit": "cover",
            "src": item.avatarPath
          }
        }, [_c('div', {
          attrs: {
            "slot": "error"
          },
          slot: "error"
        }, [_c('img', {
          attrs: {
            "src": _vm.$defaultAvatarUrl
          }
        })])]), _c('p', [_vm._v(" " + _vm._s(item.customerName) + " "), _c('span', {
          staticClass: "color9"
        }, [_vm._v(_vm._s(_vm._f("formatPhone")(item.cellphone)))])])], 1);
      }
    }], null, false, 2856992649),
    model: {
      value: _vm.searchUserKeyword,
      callback: function callback($$v) {
        _vm.searchUserKeyword = $$v;
      },
      expression: "searchUserKeyword"
    }
  }), _vm.currentUser ? _c('div', {
    staticClass: "current-user x3 aic"
  }, [_c('div', {
    staticClass: "x aic"
  }, [_c('el-image', {
    staticClass: "avatar",
    attrs: {
      "fit": "cover",
      "src": _vm.currentUser.avatarPath
    }
  }, [_c('div', {
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [_c('img', {
    attrs: {
      "src": _vm.$defaultAvatarUrl
    }
  })])]), _c('p', [_vm._v(" " + _vm._s(_vm.currentUser.customerName) + " "), _c('span', {
    staticClass: "color9"
  }, [_vm._v(_vm._s(_vm._f("formatPhone")(_vm.currentUser.cellphone)))])])], 1), !_vm.formData.id ? _c('svg-icon', {
    staticClass: "fs20 pointer",
    attrs: {
      "icon-class": "delete"
    },
    on: {
      "click": function click($event) {
        _vm.currentUser = null;
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _c('el-form-item', {
    attrs: {
      "label-width": "40px"
    }
  }, [_vm.currentUser ? _c('div', {
    staticClass: "current-user x3 aic"
  }, [_c('div', {
    staticClass: "x aic"
  }, [_c('el-image', {
    staticClass: "avatar",
    attrs: {
      "fit": "cover",
      "src": _vm.currentUser.avatarPath
    }
  }, [_c('div', {
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [_c('img', {
    attrs: {
      "src": _vm.$defaultAvatarUrl
    }
  })])]), _c('p', [_vm._v(" " + _vm._s(_vm.currentUser.name) + " "), _c('span', {
    staticClass: "color9"
  }, [_vm._v(_vm._s(_vm._f("formatPhone")(_vm.currentUser.cellphone)))])])], 1), _c('p', {
    staticClass: "tips2"
  }, [_vm._v("不支持变更用户")])]) : _vm._e()]), _c('el-form-item', {
    attrs: {
      "label": "认证类型:",
      "prop": "authType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    staticClass: "f-radio-oval",
    model: {
      value: _vm.formData.authType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "authType", $$v);
      },
      expression: "formData.authType"
    }
  }, _vm._l(_vm.authTypes, function (item, index) {
    return _c('el-radio', {
      key: index,
      attrs: {
        "border": "",
        "label": item.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.authSlaveTypeList.length ? _c('el-form-item', {
    attrs: {
      "label": "认证标识：",
      "prop": "authSlaveType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.authSlaveType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "authSlaveType", $$v);
      },
      expression: "formData.authSlaveType"
    }
  }, _vm._l(_vm.authSlaveTypeList, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1) : _vm._e(), _vm.permissionList.length ? _c('el-form-item', {
    attrs: {
      "prop": "permissionList",
      "label": "应用权限："
    }
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.permissionList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "permissionList", $$v);
      },
      expression: "formData.permissionList"
    }
  }, _vm._l(_vm.permissionList, function (item) {
    return _c('el-checkbox', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": _vm.avatarLabel,
      "prop": "authAvatarPath",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('f-upload', {
    attrs: {
      "required": "",
      "type": 2,
      "default-file-list": _vm.formData.authAvatarPath ? [_vm.formData.authAvatarPath] : undefined
    },
    on: {
      "on-success": _vm.avatarUploadSuccess
    }
  })], 1), _vm.formData.authType == 1 && _vm.formData.authSlaveType == 2 || _vm.formData.authType == 3 ? [_c('el-form-item', {
    attrs: {
      "prop": "authName",
      "label": "姓名称呼：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "input",
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.authName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "authName", $$v);
      },
      expression: "formData.authName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "estateIdList",
      "label": "关联楼盘：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('autocomplete', {
    staticClass: "input",
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "keywordName": "estateName",
      "defaultKeyword": _vm.defaultKeyword,
      "options": _vm.autocompleteOptions,
      "currentValue": _vm.formData.estateId
    },
    on: {
      "setMoreValue": _vm.addEstateTag
    }
  }), _vm.estates.length ? _c('div', {
    staticClass: "estate-tags"
  }, _vm._l(_vm.estates, function (tag) {
    return _c('el-tag', {
      key: tag.id,
      attrs: {
        "closable": "",
        "disable-transitions": false
      },
      on: {
        "close": function close($event) {
          return _vm.removeEstateTag(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 1) : _vm._e()], 1)] : _vm._e(), _vm.formData.authType != 3 && _vm.formData.authSlaveType != 2 ? [_c('el-form-item', {
    attrs: {
      "prop": "authName",
      "label": "认证名称：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "input",
    attrs: {
      "placeholder": "建议不超过8个汉字"
    },
    model: {
      value: _vm.formData.authName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "authName", $$v);
      },
      expression: "formData.authName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "description",
      "label": "一句话描述：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "input",
    attrs: {
      "placeholder": "建议不超过15个汉字"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)] : _vm._e(), _vm.formData.authType != 2 ? [_vm.formData.authType == 1 && _vm.formData.authSlaveType !== 2 ? _c('el-form-item', {
    attrs: {
      "label": "AI名片：",
      "prop": "aiCardStatus",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.aiCardStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "aiCardStatus", $$v);
      },
      expression: "formData.aiCardStatus"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true
    }
  }, [_vm._v("开启")]), _c('el-radio-button', {
    attrs: {
      "label": false
    }
  }, [_vm._v("关闭")])], 1), _c('p', {
    staticClass: "tips"
  }, [_vm._v(" 开启后，该用户可在个人主页维护名片内容；名片将呈现在个人主页，以及从挑房页面访问推荐楼盘时，楼盘联系人将替换成该用户名片； ")])], 1) : [_c('el-form-item', {
    attrs: {
      "label": "联系电话：",
      "prop": "cardEditVo.cellphone",
      "rules": [_vm.$formRules.required(), _vm.$formRules.phoneAll]
    }
  }, [_c('el-input', {
    staticClass: "input",
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.cardEditVo.cellphone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.cardEditVo, "cellphone", $$v);
      },
      expression: "formData.cardEditVo.cellphone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "cardEditVo.wechatNo",
      "label": "微信号："
    }
  }, [_c('el-input', {
    staticClass: "input",
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.cardEditVo.wechatNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.cardEditVo, "wechatNo", $$v);
      },
      expression: "formData.cardEditVo.wechatNo"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "cardEditVo.wechatQrCode",
      "label": "微信二维码："
    }
  }, [_c('f-upload', {
    attrs: {
      "type": 2,
      "default-file-list": _vm.formData.cardEditVo.wechatQrCode ? [_vm.formData.cardEditVo.wechatQrCode] : undefined
    },
    on: {
      "on-success": _vm.qrUploadSuccess
    }
  })], 1)]] : _vm._e()], 2), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }