<template>
  <el-autocomplete
    :placeholder="options.placeholder || placeholder"
    prefix-icon="el-icon-search"
    v-model="keyword"
    value-key="name"
    :fetch-suggestions="querySearchAsync"
    @select="handleSelect"
    :size="size"
  >
    <template slot-scope="{ item }">
      {{ item.name }}
    </template>
  </el-autocomplete>
</template>

<script>
export default {
  props: {
    // 编辑时所选中的值
    currentValue: {
      type: [String, Number],
      default: ""
    },
    size: {
      type: String,
      default: "medium"
    },
    keywordName: {
      type: String,
      default: "keyword"
    },
    defaultKeyword: {
      type: String,
      default: ""
    },
    options: {
      type: Object,
      default: () => {
        return {
          placeholder: "输入姓名或手机号搜索",
          name: "name", // 键名
          value: "value", // 键值
          func: null, // 搜索的函数
          query: {} //函数的参数
        };
      }
    }
  },
  data() {
    return {
      keyword: "",
      placeholder: "输入姓名或手机号搜索",
      list: []
    };
  },
  watch: {
    // 清空搜索，则赋值为空
    keyword(e) {
      if (!e) {
        this.$emit("setValue", "");
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const { options, formatList, defaultKeyword } = this;
      let query = {};
      if (options.query) {
        query = options.query;
      }
      query[this.keywordName] = defaultKeyword;
      const data = await options.func(query);
      let list = data.list || data;
      this.list = formatList(list);
      this.setKeywordByValue();
    },
    setKeywordByValue() {
      const { list, currentValue } = this;
      for (let i in list) {
        if (currentValue == list[i].value) {
          this.keyword = list[i].name;
          return;
        }
      }
    },
    formatList(data) {
      const { options } = this;
      // 按照指定名称格式化数据
      let list = [];
      data = data || [];
      data.forEach(e => {
        let item = {
          name: e[options.name],
          value: e[options.value]
        };
        list.push(item);
      });
      return list;
    },
    async querySearchAsync(keyword, cb) {
      const { options, formatList } = this;
      if (!keyword) {
        cb([]);
        return;
      }
      let query = {};
      if (options.query) {
        query = options.query;
      }
      query[this.keywordName] = keyword;
      const data = await options.func(query);
      let list = data.list || data;
      list = formatList(list);
      cb(list);
    },
    handleSelect(e) {
      this.$emit("setValue", e.value);
      this.$emit("setMoreValue", e);
    }
  }
};
</script>
