<template>
  <div class="user-auth base-container">
    <div class="header-mol">
      <div class="info">
        <div class="title">用户认证</div>
        <div class="summary">
          认证用户，不同认证标识在小程序端享受不同的服务特权
        </div>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" @click="showEditDialog()"
          >添加认证用户</el-button
        >
      </div>
    </div>
    <div class="filter">
      <el-input
        placeholder="认证名称"
        v-model="queryData.authName"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        class="select"
        v-model="queryData.selectAuthType"
        placeholder="认证标识"
        clearable
        @change="search"
      >
        <el-option
          :value="item.value"
          :label="item.name"
          v-for="item in authTypeList"
          :key="item.value"
        ></el-option>
      </el-select>
      <el-select
        class="select"
        v-model="queryData.auditStatus"
        placeholder="认证状态"
        clearable
        @change="search"
      >
        <el-option value="1" label="正常"></el-option>
        <el-option value="0" label="待审核"></el-option>
      </el-select>
    </div>
    <el-table class="body" :data="list" :row-class-name="tableRowClassName">
      <el-table-column label="认证信息" width="250">
        <template slot-scope="scope">
          <div class="user-base">
            <el-image
              class="avatar"
              fit="cover"
              :src="scope.row.authAvatarPath"
            >
              <img :src="$defaultAvatarUrl" slot="error" />
            </el-image>
            <div class="name-wrap">
              <div class="title">
                {{ scope.row.authName
                }}<span
                  class="f-top-badge"
                  v-if="scope.row.top && scope.row.top > 0"
                >
                  荐{{ scope.row.top }}
                </span>
              </div>
              <p class="sum">{{ scope.row.description }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系方式" width="140">
        <template v-slot="{ row }">
          <template v-if="row.cardEditVo">
            <div class="title" v-if="row.cardEditVo.cellphone">
              {{ row.cardEditVo.cellphone | formatPhone }}
            </div>
            <div class="title" v-else>-</div>
            <p>{{ row.cardEditVo.wechatNo || "-" }}</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="关联用户" width="140">
        <template v-slot="{ row }">
          <template v-if="row.bindingInfo && row.bindingInfo.bindingId">
            <div class="title">
              {{ row.bindingInfo.bindingName || "-" }}
            </div>
            <p v-if="row.bindingInfo.bindingPhone">
              {{ row.bindingInfo.bindingPhone || "-" }}
            </p>
            <p v-else>{{ row.bindingInfo.bindingPhone || "-" }}</p>
          </template>
          <p v-else>未关联</p>
        </template>
      </el-table-column>
      <el-table-column label="认证标识">
        <template v-slot="{ row }">
          <div
            class="title"
            :style="{
              color:
                authSlaveTypeMaps[row.authSlaveType] &&
                authSlaveTypeMaps[row.authSlaveType].color
            }"
          >
            {{ row.authSlaveTypeName }}
          </div>
          <p v-if="row.authType !== 2">{{ row.authTypeName }}</p>
        </template>
      </el-table-column>
      <el-table-column label="更新人" prop="lastEditUserName">
        <template slot-scope="{ row }">
          <div class="title">{{ row.lastEditUserName || "-" }}</div>
          <p>{{ row.lastEditDate || "-" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="认证状态">
        <template slot-scope="{ row }">
          <template v-if="!row.visible">
            <div class="title">隐藏</div>
          </template>
          <template v-else>
            <div class="title" :class="{ red: row.auditStatus != 1 }">
              {{ ["待审核", "正常"][row.auditStatus] }}
            </div>
            <p v-if="row.auditStatus == 2">拒绝理由：{{ row.auditRemark }}</p>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220px">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="scope.row.auditStatus == 0"
            @click.stop="showAuditDialog(scope.row)"
            >审核</el-button
          >
          <el-button type="text" @click.stop="showEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            @click.stop="cancelAuthTop(scope.row.id)"
            v-if="scope.row.top && scope.row.top > 0"
            >取荐</el-button
          >
          <el-dropdown
            :ref="'topUserDropdown' + scope.$index"
            trigger="click"
            v-else
          >
            <el-button type="text" class="mw10-i">
              推荐
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <div class="f-top-dropdown">
                <div class="title">推荐权重<span class="red">*</span></div>
                <el-input
                  v-model.number="curTopStr"
                  maxlength="3"
                  placeholder="值越小越靠前"
                ></el-input>
                <div class="foot">
                  <el-button
                    size="mini"
                    @click="cancelTopDropdown(scope.$index)"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="editAuthTop(scope.row.id, scope.$index)"
                    >确定</el-button
                  >
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="text" @click="deleteCustomerAuth(scope.row.id)">
            删除
          </el-button>
          <el-button type="text" @click="customerShow(scope.row.id)">
            {{ scope.row.visible ? "隐藏" : "显示" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getPlatformCustomerAuthPage"
    ></f-pagination>
    <edit
      v-if="toggleEditDialog"
      :visible.sync="toggleEditDialog"
      :options="currentRow"
      :authTypes="authTypes"
      @refresh="getPlatformCustomerAuthPage"
    ></edit>
  </div>
</template>

<script>
import Edit from "./_components/edit";
import config from "./_js/config";
import {
  getPlatformCustomerAuthPage,
  deleteCustomerAuth,
  editAuthTop,
  customerShow,
  auditUserAuth,
  getAuthTypeList
} from "@/api/visitorManage";
export default {
  components: {
    Edit
  },
  data() {
    return {
      ...config,
      authTypeList: [],
      authTypes: [],
      toggleEditDialog: false,
      list: [],
      pageSizes: [10, 20, 30, 50],
      total: 0, //列表总数
      queryData: {
        authName: "",
        selectAuthType: "", // 认证类型：1-地产达人,2-置业顾问,3-PCN经纪,4-媒体号
        auditStatus: "", //0 审核中 1审核通过
        pageNum: 1,
        pageSize: 10
        // sort: 1 //待审核排在最前面
      },
      curTopStr: "",
      currentRow: {} // 当前操作行
    };
  },
  created() {
    this.init();
    this.getPlatformCustomerAuthPage();
  },
  methods: {
    init() {
      // 判断是否为分销平台
      if (this.$route.path.includes("distribution")) {
        this.authSlaveType = this.authSlaveType.filter(item => item.type !== 1);
      } else {
        this.authSlaveType = this.authSlaveType.filter(item => item.type !== 2);
      }
      this.getAuthTypeList();
    },
    // 查询认证类型
    async getAuthTypeList() {
      const res = await getAuthTypeList();
      if (res) {
        // 认证类型列表，查询用
        this.authTypeList = res.reduce((arr, item) => {
          return [...arr, ...item.children];
        }, []);
        // 认证类型列表，编辑用
        this.authTypes = res;
      }
    },
    tableRowClassName({ row }) {
      // 给行添加className
      if (!row.visible) {
        return "disabled";
      }
    },
    async search() {
      this.queryData.pageNum = 1;
      this.getPlatformCustomerAuthPage();
    },
    async editAuthTop(id, index) {
      //推荐
      const top = Number(this.curTopStr);
      if (!top && top < 1) {
        this.$showError("请输入大于0的数字");
        return;
      }
      const res = await editAuthTop({ id, top });
      if (res) {
        this.cancelTopDropdown(index);
        this.getPlatformCustomerAuthPage();
        this.curTopStr = "";
      }
    },
    async cancelAuthTop(id) {
      //取荐
      const res = await editAuthTop({ id, top: -1 });
      if (res) {
        this.getPlatformCustomerAuthPage();
      }
    },
    cancelTopDropdown(index) {
      //关闭推荐下拉框
      this.$refs[`topUserDropdown${index}`].hide();
    },
    deleteCustomerAuth(id) {
      //删除用户
      this.$showConfirm(
        async () => {
          const res = await deleteCustomerAuth({ id });
          if (res) {
            this.getPlatformCustomerAuthPage();
            this.$showSuccess("删除成功");
          }
        },
        "删除认证后，当前用户不再显示认证用户特权，认证期间产生的数据会继续保留！",
        "删除认证"
      );
    },
    async customerShow(id) {
      //取荐
      const res = await customerShow({ id });
      if (res) {
        this.$showSuccess("操作成功");
        this.getPlatformCustomerAuthPage();
      }
    },
    async getPlatformCustomerAuthPage() {
      //获取认证用户列表
      const res = await getPlatformCustomerAuthPage(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    showEditDialog(row) {
      if (row) {
        this.currentRow = row;
      } else {
        this.currentRow = {};
      }
      this.toggleEditDialog = true;
    },
    showAuditDialog({ id }) {
      //显示审核对话框
      const handleFn = async status => {
        const res = await auditUserAuth({ id, status });
        const msg = status == 1 ? "审核通过" : "删除成功";
        if (res) {
          this.$showSuccess(msg);
          this.getPlatformCustomerAuthPage();
        }
      };
      this.$confirm("分析师申请入驻审核", "审核", {
        confirmButtonText: "通过审核",
        cancelButtonText: "删除信息",
        cancelButtonClass: "el-button--danger",
        distinguishCancelAndClose: true
      })
        .then(() => handleFn(1))
        .catch(action => {
          if (action === "cancel") handleFn(2);
        });
    }
  }
};
</script>
<style lang="scss">
.user-dropdown {
  height: 50px;
  display: flex;
  align-items: center;
  .avatar {
    height: 28px;
    width: 28px;
    border-radius: 100%;
    margin-right: 10px;
  }
}
</style>

<style scoped lang="scss">
::v-deep .el-table {
  td {
    vertical-align: top;
  }
  .el-button {
    padding: 0;
  }
  .disabled {
    .title,
    p {
      color: #aaa;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
    }
    img {
      opacity: 0.5;
    }
  }
}
.user-auth {
  min-height: 100%;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
  background: #fff;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    .title {
      line-height: 16px;
      margin-top: 5px;
    }
    p {
      color: #aaa;
      line-height: 120%;
      margin-top: 10px;
    }
    .user-base {
      display: flex;
      .avatar {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .name-wrap {
        padding: 5px 0 5px 10px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .title {
          margin-top: 0;
        }
        .sum {
          width: 80%;
          color: #999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .badge {
      font-size: 12px;
      float: left;
      padding: 2px 10px;
      border-radius: 2px;
    }
    .badge1 {
      color: #31c6c0;
      background-color: rgba(49, 198, 192, 0.109803921568627);
    }
    .badge2 {
      color: #3366ff;
      background-color: rgba(51, 102, 255, 0.0980392156862745);
    }
    .badge3 {
      color: #f49432;
      background-color: rgba(244, 148, 50, 0.129411764705882);
    }
    .badge4 {
      color: #7f8ea6;
      background-color: rgba(246, 247, 249, 1);
    }
  }
  .edit-dialog {
    .current-user {
      margin-top: 30px;
      height: 55px;
      background: #fafafc;
      padding: 0 16px;
      .user-avatar {
        width: 28px;
        height: 28px;
        border-radius: 100%;
        margin-right: 10px;
      }
    }
    .form {
      padding: 0 60px 0 0;
      .auth-content-wrap {
        display: flex;
      }
      .input-group {
        flex: 1;
        .item {
          & + .item {
            margin-top: 30px;
          }
          p {
            span {
              color: #999;
            }
          }
          .input {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
      .tips {
        margin-top: 20px;
        color: #999;
        line-height: 1.5;
      }
      .tips2 {
        line-height: 40px;
        color: #8691a3;
      }
    }
  }
  .current-user {
    .avatar {
      margin-right: 10px;
    }
  }
  .filter {
    padding-top: 20px;
    .select {
      width: 150px;
      margin-left: 10px;
    }
  }
}
</style>
