var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-autocomplete', {
    attrs: {
      "placeholder": _vm.options.placeholder || _vm.placeholder,
      "prefix-icon": "el-icon-search",
      "value-key": "name",
      "fetch-suggestions": _vm.querySearchAsync,
      "size": _vm.size
    },
    on: {
      "select": _vm.handleSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }]),
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }