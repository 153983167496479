const config = {
  authSlaveType: [
    // 1-地产达人,2-置业顾问, 3-PCN经纪 5 新房助理6驻场客服7项目经理
    // type 1 购房平台 2 分销平台
    {
      id: 1,
      label: "地产达人",
      type: 1
    },
    {
      id: 2,
      label: "置业顾问",
      type: 1
    },
    {
      id: 3,
      label: "PCN经纪",
      type: 1
    },
    {
      id: 4,
      label: "媒体号",
      type: ""
    },
    {
      id: 5,
      label: "新房助理",
      type: 2
    },
    {
      id: 6,
      label: "驻场客服",
      type: 2
    },
    {
      id: 7,
      label: "项目经理",
      type: 2
    },
    {
      id: 8,
      label: "销秘",
      type: 2
    },
    {
      id: 9,
      label: "销售经理",
      type: 2
    },
    {
      id: 10,
      label: "财务人员",
      type: 2
    }
  ],
  authSlaveTypeMaps: {
    // 1-地产达人,2-置业顾问, 3-PCN经纪 5 新房助理6驻场客服7项目经理
    1: {
      label: "地产达人",
      color: "green"
    },
    2: {
      label: "置业顾问",
      color: "#3366ff"
    },
    3: {
      label: "PCN经纪",
      color: "#f49432"
    },
    4: {
      label: "媒体号",
      color: "#7f8ea6"
    },
    5: {
      label: "新房助理",
      color: "#f49432"
    },
    6: {
      label: "驻场客服",
      color: "#3366ff"
    },
    7: {
      label: "项目经理",
      color: "#31C7C0"
    },
    8: {
      label: "销秘",
      color: "#f49432"
    },
    9: {
      label: "销售经理",
      color: "#3366ff"
    },
    10: {
      label: "财务人员",
      color: "#31C7C0"
    }
  }
};
export default config;
